import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
// import DemoModal from 'components/DemoModal';

// import Section from './GetStarted.styled';
import 'styles/components/GetStarted.scss';
import { signUp } from '../../utils/contentProvider/function';

const GetStarted = ({ toggleSignup }) => {
  const [showDemoModal, setShowDemoModal] = useState(false);

  function openDemoModal() {
    setShowDemoModal(true);
  }

  function closeDemoModal() {
    setShowDemoModal(false);
  }

  function clickHandler(e) {
    if (e.target.id === 'footer-demo-modal') setShowDemoModal(false);
  }

  useEffect(() => {
    window.addEventListener('click', clickHandler);

    return () => window.removeEventListener('click', clickHandler);
  }, [clickHandler]);

  return (
    <section className="styled-get-started">
      <div className="container">
        <div className="flex flex-wrap align-items-center">
          <div className="index-get-started-text reveal-1">
            <h2>Experience the future of design review with ruttl </h2>
            <div className="get-started-buttons">
              <button
                type="button"
                onClick={() => signUp(toggleSignup)}
                className="button">
                Get started for free
              </button>
              <button
                type="button"
                className="button button-white"
                onClick={openDemoModal}>
                Watch Demo
              </button>
            </div>
          </div>
          <div className="index-get-started-scroll">
            <div className="scroll-single"></div>
            <div className="scroll-single scroll-2"></div>
          </div>
        </div>
      </div>
      <div
        id="footer-demo-modal"
        className={cn(['demo-modal', { show: showDemoModal }])}>
        <button
          type="button"
          className="button-unstyled"
          onClick={closeDemoModal}>
          <img src="/assets/img/close.svg" alt="close" title="close" />
        </button>
        {showDemoModal && (
          <iframe
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/vkx366sknD4?rel=0"
            frameBorder="0"
            title="ruttl demo"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>
    </section>
  );
};

GetStarted.propTypes = {
  toggleSignup: PropTypes.func.isRequired,
};

export default GetStarted;
