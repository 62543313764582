import React from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStarted from 'components/GetStarted';
// import { Usecases } from 'styles/usecases';
// import { PrivilagesStyled } from '../styles/privilages';
import { signUp } from '../utils/contentProvider/function';
import 'styles/usecases.scss';
import 'styles/privilages.scss';
const Features = ({ location }) => (
  <Layout location={location}>
    {({ toggleContactUs, toggleSignup }) => (
      <>
        <Meta
          title="Website Feedback Tool | ruttl features"
          description="Leave comments on websites · Edit designs on live website · Inspect CSS · Collaboration for web designers and developers · Invite clients for feedback"
          url="https://ruttl.com/features/"
        />
        <main className="usecases-styled-main">
          <section className="features-hero">
            <div className="container">
              <h1>Website Feedback Tool</h1>
              <p>
                ruttl is full of features you really need while reviewing your
                <br />
                design, check all those important features here
              </p>
              <a href="https://web.ruttl.com" className="button">
                Get Started for Free
              </a>
            </div>
          </section>
          <div className="section features-main" id="features">
            <div className="container">
              <div className="feature-set-single" id="comment-live-website">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <img
                      src="/assets/img/leave-comments-website.jpg"
                      alt="illustration of leaving comment on live website"
                    />
                  </div>
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-50px)' }}>
                    <h2 className="feature-head" style={{ maxWidth: '420px' }}>
                      Leave comments on live websites
                    </h2>
                    <p>
                      Give quick feedback to your team through real-time
                      comments. Keep everyone in the content, design and
                      development teams on the same page. Comment on web pages
                      to share detailed inputs. You could also pin comments on
                      live pixels, give specific creative direction and make
                      work easy for your colleagues.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Comment on Live Website
                    </button>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="edit-live-website">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div className="feature-txt">
                    <h2 className="feature-head" style={{ maxWidth: '310px' }}>
                      Make edits on live websites
                    </h2>
                    <p>
                      Edit live website and get all the precise changes done
                      before that deadline. Change even the tiniest details like
                      font, spacing, live alignment for your live project.
                      Suggest accurate values for all the iterations and make
                      communication simpler.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Edit your Live Website
                    </button>
                  </div>
                  <div className="feature-img">
                    <video
                      src="/assets/img/edit-live-website.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="edit-website-content">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <video
                      src="/assets/img/edit-text.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                  <div className="feature-txt">
                    <h2 className="feature-head">Edit website content</h2>
                    <p>
                      Make seamless content changes on your website content and
                      see all the changes compiled in real time. Save your time
                      on offline drafts to finalize website content.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Edit Website Content
                    </button>
                  </div>
                </div>
              </div>

              <div className="feature-set-single" id="client-commenting">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-30px)' }}>
                    <h2 className="feature-head">Client Commenting</h2>
                    <p>
                      Website feedback has got simpler! Your clients can write
                      and even video comment without logging in on ruttl! Simply
                      send the shareability link to them and they just need to
                      type their name after writing the comment. Your clients
                      don’t face the hassles of signing up. They can also tag
                      other project stakeholders to assign any task and get work
                      done.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Check Client Commenting
                    </button>
                  </div>
                  <div className="feature-img">
                    <video
                      src="/assets/img/guest-commenting.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="static-image-review">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <video
                      src="/assets/img/review-static-images.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-30px)' }}>
                    <h2 className="feature-head">Static Image Review</h2>
                    <p>
                      Make designing and development seamless - upload multiple
                      versions of your chosen images on ruttl. Rename, delete
                      image versions at your ease. Tag your teammates, share
                      feedback with them through comments on images. Resolve
                      these comments by marking them done once the task is
                      completed. Edit, unresolve comments, send them to Trello
                      as well on the go.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Review a Static Image
                    </button>
                  </div>
                </div>
              </div>

              <div className="feature-set-single" id="replace-images">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div className="feature-txt">
                    <h2 className="feature-head">
                      Replace images on live website
                    </h2>
                    <p>
                      Quickly replace images on a live website with our fast
                      website annotation tool. Uploading and trying out various
                      images can be a task. With ruttl, you can quickly upload
                      as many images as needed across dimensions and formats
                      within no time - that too on a live website!
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Replace Images on your Website
                    </button>
                  </div>
                  <div className="feature-img">
                    <video
                      src="/assets/img/upload-image.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="inspect-css">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <img
                      src="/assets/img/show-activities.png"
                      alt="ruttl feature to inspect CSS code"
                    />
                  </div>
                  <div className="feature-txt">
                    <h2 className="feature-head">Inspect CSS</h2>
                    <p>
                      Effortlessly view clean CSS for your website. Avoid
                      wasting your time with any software to edit website
                      content. Instead, go from seeing your live website to
                      editing it in a click with ruttl. Streamline the
                      accessibility issues, redlines and other technical
                      essentials by inspecting CSS with its ease.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Start Inspecting
                    </button>
                  </div>
                </div>
              </div>

              <div className="feature-set-single" id="add-team">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-10px)' }}>
                    <h2 className="feature-head">Add your team</h2>
                    <p>
                      Invite your team members to collaborate on live projects
                      and meet deadlines. Easily notify them about all the
                      changes that you are planning to make on a live project -
                      simply tag them using website comments and give them
                      real-time updates.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Add Team Members
                    </button>
                  </div>
                  <div className="feature-img">
                    <img
                      src="/assets/img/add-team.png"
                      alt="illustration of team members in ruttl"
                    />
                  </div>
                </div>
              </div>

              <div className="feature-set-single" id="review-mobile-web">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <video
                      src="/assets/img/responsive.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                  <div className="feature-txt">
                    <h2 className="feature-head">Review mobile web</h2>
                    <p>
                      Check and keep your website updated across devices.
                      Quickly switch between desktop and mobile view to make
                      changes as needed. Never miss any details or macro
                      changes. Make your website mobile and web friendly through
                      quick reviews on ruttl.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Start Reviewing
                    </button>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="versioning">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-13px)' }}>
                    <h2 className="feature-head" style={{ maxWidth: '360px' }}>
                      Record every version on website
                    </h2>
                    <p style={{ maxWidth: '380px' }}>
                      Revisit all the website versions you were working on by
                      recording them. Explore all the changes you have made
                      through the save option. Never miss any updates that were
                      made by your teams. Navigate all the versions in no time
                      and easily collaborate to optimize your website.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Create Website Versions
                    </button>
                  </div>
                  <div className="feature-img">
                    <img
                      src="/assets/img/record-version.png"
                      alt="versioning feature of ruttl"
                    />
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="share-project">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <img
                      src="/assets/img/share-project-with-clients.png"
                      alt="ruttl project sharing feature"
                    />
                  </div>
                  <div className="feature-txt">
                    <h2 className="feature-head" style={{ maxWidth: '310px' }}>
                      Share project with clients
                    </h2>
                    <p>
                      With the shareable link, always keep your clients in loop
                      by sharing a unique project link with them, which does not
                      require logging in to ruttl. This way, your clients could
                      view all the progress that you and the team have made. You
                      end up reducing the turnaround time and the feedback loop.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Collaborate on a Project
                    </button>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="show-activity">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-30px)' }}>
                    <h2 className="feature-head">Show all activities</h2>
                    <p>
                      From the Activities panel, check all your website edits,
                      content changes and comments on web pages. This way, your
                      team never loses track of ongoing activities. Anyone from
                      your team could check all the latest activities done on a
                      website project. Your development team could easily
                      navigate those suggestions and use them for the final
                      codes to create pixel perfect designs.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Check the Activities Panel
                    </button>
                  </div>
                  <div className="feature-img">
                    <img
                      src="/assets/img/show-activity.png"
                      alt="ruttl activities tab"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="privilages-styled">
            <section className="faq-main" id="faq">
              <div className="container">
                <div className="flex flex-wrap">
                  <div className="faq-heading-area">
                    <div className="sticky-heading">
                      <h2 className="heading">FAQ</h2>
                      <p>
                        Please check if your question has been answered here
                        before emailing us! We’d love to chat, but this saves
                        our time and yours!
                      </p>
                      <button
                        type="button"
                        className="button"
                        onClick={toggleContactUs}>
                        I have more questions
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 512 512">
                          <path
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="48"
                            d="M268 112l144 144-144 144M392 256H100"></path>
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="faq-question-main">
                    <Accordion allowZeroExpanded preExpanded={['a']}>
                      {[
                        {
                          id: 1,
                          uuid: 'a',
                          q: 'What happens after I make the payment?',
                          a: (
                            <>
                              Your ruttl account will be successfully upgraded
                              and it will reflect on your account.&nbsp;
                              <span className="bold">
                                Please ensure that you submit the same email
                                address with which you intend to create your
                                ruttl account, or have already created it.
                              </span>
                            </>
                          ),
                        },
                        {
                          id: 2,
                          q: 'For how long can I access the benefits of these privilege plans?',
                          a: ' These privilege plans are available for lifetime, hence there is no expiry of their benefits once you purchase them.',
                        },
                        {
                          id: 3,
                          q: 'What is the difference between Collaborators and Editors?',
                          a: 'Collaborators are clients or guests, who will only have view and comment access inside the projects, whereas editors will have access to edit the projects and webpages both.',
                        },
                        {
                          id: 4,
                          q: 'What if I wish to grant edit access to a collaborator, or add an extra editor in Privilege Pro plan?',
                          a: (
                            <>
                              By default, collaborators will only have View and
                              Comment access. If edit access has to granted to
                              users after the editor number expires, it will pe
                              charged at $4 per user account.
                            </>
                          ),
                        },
                        {
                          id: 5,
                          q: 'What if I add an extra editor, or give edit access to a collaborator?',
                          a: (
                            <>
                              Once you add the extra editor, or give edit access
                              to a collaborator, their charge will be added to
                              your total billing, and will reflect in the next
                              month’s invoice.
                            </>
                          ),
                        },

                        {
                          id: 6,
                          q: 'What if I remove edit access for a collaborator or an extra editor?',
                          a: 'Once you remove the extra editor, or remove edit access from a collaborator, their charge will be subtracted from your total billing, and will reflect in the next month’s invoice.',
                        },
                        {
                          id: 7,
                          q: 'Are these privilege plans refundable or transferrable?',
                          a: (
                            <>
                              Our privilege plans are refundable within 7
                              (seven) days of purchase. All our plans are
                              non-transferrable. If you wish to refund your
                              lifetime privilege plan within 7 days, please
                              email us at&nbsp;
                              <a href="mailto:support@ruttl.com">
                                support@ruttl.com
                              </a>
                              .
                            </>
                          ),
                        },
                        {
                          id: 8,
                          q: 'What if I wish to change my privilege plan?',
                          a: (
                            <>
                              You can always upgrade your privilege plan. Simply
                              send us an email to&nbsp;
                              <a href="mailto:support@ruttl.com">
                                support@ruttl.com
                              </a>
                              &nbsp;from your ruttl registered email ID, and let
                              us know which plan would you like to upgrade to.
                              We will send you a payment link and ask you to pay
                              the difference. Usually, the upgrades are
                              processed within 2 hours, but please give us
                              atleast 8-10 hours to process your request.
                            </>
                          ),
                        },
                      ].map(({ q, a, id, uuid }) => (
                        <AccordionItem
                          uuid={uuid}
                          key={id}
                          className="faq-single">
                          <AccordionItemHeading className="question">
                            <AccordionItemButton>
                              <h4>{q}</h4>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="answer">
                            {a}
                          </AccordionItemPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                    {/* <div className="faq-single">
                      <div className="question">
                        <h4>How can I pay for my ruttl plan?</h4>
                      </div>
                      <div className="answer">
                        <p>
                          At the moment it&apos;s a manual process however
                          moving forward, you can choose to pay for your plan
                          with a credit or debit card, PayPal, or if you’re in
                          India, through UPI or Netbanking.
                        </p>
                      </div>
                    </div>
                    <div className="faq-single">
                      <div className="question">
                        <h4>Can I switch between plans before they end?</h4>
                      </div>
                      <div className="answer">
                        <p>
                          Yes, but you can only upgrade your plan. Just email us
                          at support@ruttl.com, and we’ll sort it out for you!
                        </p>
                      </div>
                    </div>
                    <div className="faq-single">
                      <div className="question">
                        <h4>How long can I use ruttl for free?</h4>
                      </div>
                      <div className="answer">
                        <p>
                          You can use ruttl for free for 45 days and work on two
                          projects during that time. After your trial is up, you
                          can choose a plan that’s perfect for your team and
                          your projects by emailing us at support@ruttl.com or
                          simply picking the one that works for you on this
                          page!
                        </p>
                      </div>
                    </div>
                    <div className="faq-single">
                      <div className="question">
                        <h4>Which websites does ruttl work with?</h4>
                      </div>
                      <div className="answer">
                        <p>
                          ruttl works with static, WordPress, Vue.js, and React
                          sites. In fact, you can use ruttl to work on pretty
                          much every website. Just paste the website URL and get
                          started!
                        </p>
                      </div>
                    </div>
                    <div className="faq-single">
                      <div className="question">
                        <h4>Which integrations does ruttl currently allow?</h4>
                      </div>
                      <div className="answer">
                        <p>
                          ruttl supports Slack, Trello, and White Label at the
                          moment. We’re working to include more, and we’d love
                          to know which ones you’d like. Email your suggestions
                          to us at support@ruttl.com
                        </p>
                      </div>
                    </div>
                    <div className="faq-single">
                      <div className="question">
                        <h4>
                          I only want my clients to review, not edit the website
                          design — is this possible?
                        </h4>
                      </div>
                      <div className="answer">
                        <p>
                          Of course! You can choose to assign Inspect, Comment,
                          or View roles to anyone you invite. This way, your
                          managers, clients, and other key stakeholders can
                          review and/or leave comments while your team members
                          make edits to live websites to incorporate those
                          changes!
                        </p>
                      </div>
                    </div>
                    <div className="faq-single">
                      <div className="question">
                        <h4>
                          How can I invite more team members on my project?
                        </h4>
                      </div>
                      <div className="answer">
                        <p>
                          Simply click on “Invite” and add the email addresses
                          of the people you want to work with on the project.
                          Assign the roles you’d like them to have, and just
                          like that, your website design is underway!
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
            <div className="get-started-center">
              <GetStarted toggleSignup={toggleSignup} />
            </div>
          </div>
        </main>
      </>
    )}
  </Layout>
);

Features.propTypes = {
  location: PropTypes.object,
};

export default Features;
